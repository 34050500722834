.Career_Main {
    background-image: url("../../Image/Career/career_bg.svg");
    background-repeat: no-repeat;
    background-position: top;
    color: var(--bs-font-darkblue);
}

.Career_Main h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 55px;
}

.Career_Main h5 {
    margin-bottom: 0;
}

.career_bg {
    background-image: url("../../Image/Career/Jobs_bg.webp"), url("../../Image/Career/Jobs_bg2.webp");
    background-repeat: no-repeat;
    background-position: 3% 13%, right bottom;
    background-size: 200px, 300px;
}

.career_jobs {
    background: linear-gradient(101.63deg, #086AD7 -0.01%, #0E4E95 99.99%);
    border-radius: 17px;
    color: white;
}

.career_jobs h3 {
    font-weight: 600;
}

.career_jobs text {
    font-weight: 400;
}

.jobs_1,
.jobs_2,
.jobs_3,
.jobs_4,
.jobs_5,
.jobs_6 {
    background-repeat: no-repeat;
    padding: 30px 30px;
}

.jobs_1, .jobs_6 {
    background-image: url("../../Image/Career/React.svg");
    background-position: 115% 130%;
}

.jobs_2 {
    background-image: url("../../Image/Career/Flutter.svg");
    background-position: 100% bottom;
}

.jobs_3 {
    background-image: url("../../Image/Career/node-js.svg");
    background-size: 200px;
    background-position: 115% 120%;
}

.jobs_4 {
    background-image: url("../../Image/Career/UiUx.svg");
    background-position: 120% 130%;
}

.jobs_5 {
    background-image: url("../../Image/Career/BDE.svg");
    background-size: 200px;
    background-position: 102% 115%;
}

.career_jobs_btn {
    width: 100%;
    height: 50px;
    background-color: transparent;
    color: white;
    border: 2px solid #d7e7f9de;
    border-radius: 10px;
    font-size: 17px;
}

/*  */

.career_ser_bg {
    /* background-color: var(--home-main-bg); */
    background-color: #f8f9fb;
}

.career_box {
    background-color: #FFF;
    box-shadow: 5px 8px 30px 0 rgba(31, 35, 37, 0.08);
}

.career_box title {
    color: #000;
}

.career_box_img {
    background-color: #C7E2FF;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: auto;
}

.career_box_img img {
    margin: auto;
}

@media (max-width: 1400px) {
    .career_bg {
        background-image: none, url("../../Image/Career/Jobs_bg2.webp");
        background-position: 3% 13%, 106% 103%;
    }
}

@media (max-width: 992px) {
    .career_bg {
        background-image: none, none;
    }
}