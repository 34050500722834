.mainFooter img {
    user-select: none;
}

.mainFooter {
    box-shadow: 0px -1px 4px 0px #675ce338;
    background-color: rgba(0, 143, 175, 0.04);
    background-image: url('../../Image/Home/Footer/Background.svg'), url('../../Image/Home/Footer/Ball_1.svg');
    background-repeat: no-repeat;
    background-position: top, left;
}

.footer_text a {
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    color: black;
    text-decoration: none;
}

.footer_text a:hover {
    color: darkblue;
}

.footer_Social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 25px;
    background-color: #DAE9F9;
}

.footer_icon {
    padding: 10px;
    /* border: 1px solid black; */
    display: flex;
}

.box {
    --size: 90px;
    width: var(--size);
    height: var(--size);
    transition: transform 300ms;
    padding: 10px;
}

.item {
    width: 100%;
    height: 100%;
}

.box:hover {
    transform: scale(1.6) translateY(-20px) rotate(-40deg);
}

.box:has(+ .box:hover),
.box:hover+.box {
    transform: scale(1.4) translateY(-10px);
}

@media (max-width: 576px) {
    .box {
        --size: 50px;
        width: var(--size);
        height: var(--size);
        padding: 0px !important;
    }

    .item a img {
        width: 55px !important;
    }
}