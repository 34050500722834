.Contact_Main {
    background-image: url("../../Image/Contact/Contact_bg.svg");
    background-repeat: no-repeat;
    background-position: right;
    color: white;
}

.Contact_Main h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 55px;
}

.Contact_Main h5 {
    margin-bottom: 0;
}

.Contact_sub {
    box-shadow: 0px 4.417910575866699px 24.298507690429688px 6.626865863800049px #323F7C0A;
    padding: 20px;
    border-radius: 12px;
}

.Contact_sub h6 {
    color: var(--bs-color-blue);
    font-weight: 400;
}

.Contect_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 25px;
    background-color: #DAE9F9;
}

.Contact_Social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 25px;
    border: 2.21px solid #523EE81C;
}

.Contact_Men_top {
    margin-top: 120px;
}

.Contact_Men_1 {
    display: block;
    margin-top: -120px;
}

.Contact_Men {
    display: block;
    margin-top: -160px;
}

.Contact_sub1 {
    margin-top: 20px;
}

@media (max-width: 990px) {
    .Contact_sub1 {
        margin-top: 110px !important;
    }
}

@media (max-width: 376px) {
    .Contact_Men {
        width: 250px;
    }
}
