@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap');

.Home_Main {
    background-color: var(--home-main-bg);
    background-image: url("../Image/Home/Background/Bg_Ball.svg"), url("../Image/Home/Background/Bg_Ball_Group.svg");
    background-repeat: no-repeat;
    background-position: 3% 5%, 50% bottom;
    z-index: 2;
    position: relative;
}

.Home_MainText {
    margin: 130px 0 0 200px;
    user-select: none;
}

.Home_bgText {
    margin-left: 40px;
    z-index: -1;
    position: relative;
}

.Home_MainText_h1 {
    margin-top: -55px;
    margin-left: 40px;
    z-index: 1;
    position: relative;
}

.Home_MainText_h1 h1 {
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    font-size: 4rem;
    line-height: 1em;
    color: var(--bs-font-darkblue);
}

.animate {
    font-size: 50px;
    margin: 100px 0 0;
    font-family: 'Lato', sans-serif;
    opacity: 0.4;
}

.animate span {
    display: inline-block;
}

.animate span:nth-of-type(2) {
    animation-delay: .1s;
}

.animate span:nth-of-type(3) {
    animation-delay: .2s;
}

.animate span:nth-of-type(4) {
    animation-delay: .3s;
}

.animate span:nth-of-type(5) {
    animation-delay: .4s;
}

.animate span:nth-of-type(6) {
    animation-delay: .5s;
}

.animate span:nth-of-type(7) {
    animation-delay: .6s;
}

.animate span:nth-of-type(8) {
    animation-delay: .7s;
}

.animate span:nth-of-type(9) {
    animation-delay: .8s;
}

.animate span:nth-of-type(10) {
    animation-delay: .9s;
}

.three span {
    color: #086AD7;
    opacity: 0;
    transform: translate(-300px, 0) scale(0);
    animation: sideSlide .5s forwards;
    font-size: 7rem;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #086AD7;
    font-family: 'Lato', sans-serif;
    line-height: 1rem;
}

@keyframes sideSlide {
    60% {
        transform: translate(20px, 0) scale(1);
    }

    80% {
        transform: translate(20px, 0) scale(1);
    }

    99% {
        transform: translate(0) scale(1.1);
    }

    100% {
        transform: translate(0) scale(1);
        opacity: 1;
    }
}

.home_lastbox {
    margin-top: -100px;
}

/* Side Ball */

.ball {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    opacity: 0.8;
}

.ball:nth-child(1) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: left 1.1s infinite ease-in-out;
    -moz-animation: left 1.1s infinite ease-in-out;
    animation: left 1.1s infinite ease-in-out;
}

.ball:nth-child(3) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: right 1.05s infinite ease-in-out;
    -moz-animation: right 1.05s infinite ease-in-out;
    animation: right 1.05s infinite ease-in-out;
}

.ball:nth-child(4) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: left 1.15s infinite ease-in-out;
    -moz-animation: left 1.15s infinite ease-in-out;
    animation: left 1.15s infinite ease-in-out;
}

.ball:nth-child(5) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: right 1.1s infinite ease-in-out;
    -moz-animation: right 1.1s infinite ease-in-out;
    animation: right 1.1s infinite ease-in-out;
}

.ball:nth-child(6) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: left 1.05s infinite ease-in-out;
    -moz-animation: left 1.05s infinite ease-in-out;
    animation: left 1.05s infinite ease-in-out;
}

.ball:nth-child(7) {
    background: var(--bs-animation-ball-bg);
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
}

@-webkit-keyframes right {
    0% {
        -webkit-transform: translate(-15px);
    }

    50% {
        -webkit-transform: translate(15px);
    }

    100% {
        -webkit-transform: translate(-15px);
    }
}

@-webkit-keyframes left {
    0% {
        -webkit-transform: translate(15px);
    }

    50% {
        -webkit-transform: translate(-15px);
    }

    100% {
        -webkit-transform: translate(15px);
    }
}

@-moz-keyframes right {
    0% {
        -moz-transform: translate(-15px);
    }

    50% {
        -moz-transform: translate(15px);
    }

    100% {
        -moz-transform: translate(-15px);
    }
}

@-moz-keyframes left {
    0% {
        -moz-transform: translate(15px);
    }

    50% {
        -moz-transform: translate(-15px);
    }

    100% {
        -moz-transform: translate(15px);
    }
}

@keyframes right {
    0% {
        transform: translate(-15px);
    }

    50% {
        transform: translate(15px);
    }

    100% {
        transform: translate(-15px);
    }
}

@keyframes left {
    0% {
        transform: translate(15px);
    }

    50% {
        transform: translate(-15px);
    }

    100% {
        transform: translate(15px);
    }
}

/*  */

.Home_font {
    font-family: 'Poppins', sans-serif;
}

.Home_Features {
    background-color: #F8F9FA;
    background-image: url("../Image/Home/Background/Bg_Features.svg");
    background-repeat: no-repeat;
    background-position: 92% center;
}

.Home_Sec1 h1 {
    font-weight: 600;
    font-size: 3.15rem;
    font-family: 'Nunito', sans-serif;
}

.Home_Sec1 h5 {
    font-weight: 500;
    color: var(--font-light-color);
}

.Home_sec1_card p {
    padding: 0;
    margin: 0;
}

.Home_sec1_cardBorder1 {
    padding: 30px 25px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-color: #F8F9FA;
    box-shadow: rgba(51 98 204 / 11%) 0px 8px 24px 0px;
    transition: border 0.5s ease-in-out;
}

.Home_sec1_cardBorder1:hover {
    border: 1px solid #071C4D;
}

/* Sec - 2 */

.Home_WhatWeDo {
    background-image: url("../Image/Home/Background/Bg_Box.svg"), url("../Image/Home/Background/Bg_Box.svg");
    background-repeat: no-repeat;
    background-position: 3% bottom, 98% center;
}

.Home_sec2 h1 {
    font-weight: 600;
    font-size: 3.15rem;
    font-family: 'Nunito', sans-serif;
    color: var(--bs-font-darkblue);
}

.Home_sec2 h5 {
    color: var(--font-light-color);
    line-height: 30px;
}

.Home_sec2_title p {
    font-weight: 500;
}

.Home_sec2_text p {
    color: var(--font-light-color);
    font-weight: 500;
    font-size: 18px;
}

.Home_Btn {
    width: 100%;
    height: 50px;
    color: white;
    background-color: var(--bs-color-blue);
    border: 1px solid transparent;
    border-radius: 30px;
    font-size: 16px;
}

.Home_Btn:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
}


.home_sec2img {
    position: relative;
    animation: mymove 5s infinite ease-in-out;
}

@keyframes mymove {
    0% {
        left: 0px;
        top: 0px;
    }

    25% {
        left: 0px;
        top: 20px;
    }

    50% {
        left: 0px;
        top: 0px;
    }

    75% {
        left: 0px;
        top: 20px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

/* Home Sec - 3 */

.home_Sec3 {
    background-color: var(--home-main-bg);
    background-image: url('../Image/Home/bg.svg');
    /* background-size: auto; */
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.card {
    height: 530px !important;
    border: 1px solid transparent !important;
}

.home_Sec3_Card {
    transition: border 0.5s ease-in-out;
    transition: transform 0.5s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
}

.home_Sec3_Card:hover {
    border: 1px solid #b4b4ff !important;
    transform: scale(1.03) !important;
}

.card-img-top {
    margin: auto;
}

/* -------------------- About Sec -------------------- */

.Home_Sec_About {
    color: var(--bs-font-darkblue);
    background-image: url("../Image/Home/Background/Line.svg"),
        url("../Image/Home/Background/Line.svg"),
        url("../Image/Home/Background/Line.svg"),
        url("../Image/Home/Background/Line.svg"),
        url("../Image/Home/Background/Line.svg"),
        url("../Image/Home/Background/Line.svg");
    background-repeat: no-repeat;
    background-position: 14.28%, 28.56%, 42.84%, 57.12%, 71.4%, 85.68%;
}

.Home_Sec_About h4 {
    color: var(--bs-font-darkblue);
    text-decoration: underline var(--bs-font-darkblue);
}

.Home_Sec_About_last h4 {
    font-size: 20px;
    text-decoration: none;
    color: var(--font-light-color);
}

/* -------------------- Our Project -------------------- */

.home_Project {
    background-color: var(--home-main-bg);
}

.home_projectCard {
    position: relative;
    background-color: #fff;
    margin-top: -20px;
    border-radius: 5px;
    box-shadow: 0px 5.362500190734863px 22.34375px 0px #00000012;
}

.home_projectCard p,
.home_projectCard h6 {
    padding-left: 15px;
}

.home_Project img {
    border-radius: 5px;
}

/* -------------------- Review -------------------- */

.home_review {
    background-image: url('../Image/Home/Review_bg.svg'), url("../Image/Home/Bg_Testimonial.svg"), url("../Image/Home/Bg_Testimonial1.svg");
    background-repeat: no-repeat;
    background-position: center, left bottom, right top;
    width: 100%;
    background-size: 80%, 8%, 8%;
}

.home_review_card {
    background: linear-gradient(92.48deg, #086AD7 0.99%, #87B7ED 99.43%);
    border-radius: 10px;
    color: #fff;
    margin: 0 20px;
}

.home_review_btn {
    width: 100%;
    height: 50px;
    color: var(--bs-color-blue);
    background-color: transparent;
    border: 2px solid #D1E3F7;
    border-radius: 10px;
    font-size: 17px;
}

/* -------------------- Faq -------------------- */

.home_faq {
    background-color: var(--home-main-bg);
    background-image: url("../Image/Home/Background/Triangle.svg"), url("../Image/Home/Background/Triangle.svg");
    background-repeat: no-repeat;
    background-position: 10% 15%, right 85%;
}

.home_faq_text p {
    font-size: 18px;
}

.home_faq_text h3 {
    color: var(--bs-color-blue);
}

/* -------------------- Trial -------------------- */

.home_trial {
    background-color: var(--bs-font-darkblue);
    color: #fff;
    border-radius: 20px;
}

.home_trial h1 {
    font-family: 'Nunito', sans-serif;
}

.home_trial_yellow {
    color: #FFB116;
}

/* -------------------- Responsive -------------------- */

@media (max-width: 1760px) {
    .Home_MainText {
        margin: 100px 0 0 100px;
    }
}

@media (max-width: 1550px) {
    .Home_MainText {
        margin: 100px 0 0 50px;
    }
}

@media (max-width: 1440px) {
    .card {
        height: 510px !important;
    }

    .Home_MainText {
        margin: 100px 0 0 29px;
    }

    .home_lastbox {
        margin-top: -50px;
    }
}

@media (max-width: 1234px) {
    .Home_MainText {
        margin: 100px 0 0 40px;
    }

    .animate {
        margin: 90px 0 0;
    }

    .three span {
        font-size: 5.1rem;
    }

    .home_lastbox {
        margin-top: -20px;
    }

    .Home_MainText_h1 {
        margin-top: -45px;
    }

    .Home_MainText_h1 h1 {
        font-size: 3rem;
    }
}

@media (max-width: 990px) {
    .card {
        height: 550px !important;
    }

    .Home_sec2 h1 {
        font-size: 2.5rem;
    }

    .Home_sec2 h5 {
        font-size: 1.2rem;
    }

    .Home_sec2_text p {
        font-size: 16px;
    }

    .home_lastbox {
        display: none;
    }

    /*  */
    .Home_MainText {
        margin: 100px 0 0 30px;
    }

    .animate {
        margin: 90px 0 0;
    }

    .three span {
        font-size: 6rem;
    }

    .home_lastbox {
        margin-top: -20px;
    }

    .Home_MainText_h1 {
        margin-top: -50px;
    }

    .Home_MainText_h1 h1 {
        font-size: 3.5rem;
    }
}

@media (max-width: 770px) {
    .card {
        height: 500px !important;
    }

    .Home_MainText {
        margin: 20px 0 0 30px;
    }

    .three span {
        font-size: 5rem;
    }

    .Home_MainText_h1 {
        margin-top: -45px;
    }

    .Home_MainText_h1 h1 {
        font-size: 2.91rem;
    }
}

@media (max-width: 576px) {
    .card {
        min-height: 520px !important;
        height: max-content !important;
    }

    .ball {
        display: none;
    }

    .Home_MainText {
        margin: 0 0 50px 0;
    }

    .three span {
        font-size: 4rem;
    }

    .Home_MainText_h1 {
        margin-top: -40px;
    }

    .Home_MainText_h1 h1 {
        font-size: 2.3rem;
        line-height: 2rem;
    }

    .Home_bgText,
    .Home_MainText_h1 {
        margin-left: 20px;
    }
}

@media (max-width: 376px) {

    .Home_bgText,
    .Home_MainText_h1 {
        margin-left: 10px;
    }

    .three span {
        font-size: 3.5rem;
    }

    .Home_MainText_h1 {
        margin-top: -32px;
    }

    .Home_MainText_h1 h1 {
        font-size: 2rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 320px) {
    .three span {
        font-size: 3rem;
    }

    .Home_MainText_h1 {
        margin-top: -30px;
    }

    .Home_MainText_h1 h1 {
        font-size: 1.7rem;
        line-height: 1.3rem;
    }
}