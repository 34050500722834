.navbar {
    padding: 10px 0 !important;
    --bs-navbar-color: #000 !important;
    font-family: 'Noto Sans JP', sans-serif;
    z-index: 9 !important;
    background-color: #F4F8FD !important;
    box-shadow: 0 0 30px rgba(50, 73, 179, 0.2);
}

.nav-link {
    padding: 7px 15px !important;
    font-weight: 500 !important;
    letter-spacing: .4px;
}

.header_Name {
    margin-bottom: 0 !important;
    color: black;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: .1px;
    border-bottom: 1px solid transparent;
}

.header_Name:hover {
    border-bottom: 1px solid var(--bs-animation-ball-bg);
    color: var(--bs-animation-ball-bg);
}

.header_Name_active {
    color: var(--bs-animation-ball-bg);
}

.nav_com {
    font-family: 'Courier New', Courier, monospace;
}