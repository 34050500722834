.ser_text h1 {
    font-family: 'Nunito';
    font-size: 50px;
    line-height: 69px;
}

.ser_text h5 {
    font-weight: 500;
    line-height: 30px;
}


.services_bg {
    background-image: url("../../Image/Services/vector1.webp");
    background-repeat: no-repeat;
    background-position: 2% top;
}

.ser_card_col {
    padding: 0 20px !important;
}

.ser_card {
    background-color: white;
    border: 1px solid rgba(50, 73, 179, 0.14);
    padding: 25px;
    border-radius: 10px;
    transition: .3s ease-in-out;
}

.ser_card h4 {
    color: var(--bs-font-darkblue);
    font-family: 'Nunito';
}

.ser_card p {
    color: var(--font-light-color);
}


.ser_card:hover {
    margin-top: -8px;
    border: 1px solid transparent;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    box-shadow: rgb(44, 62, 80) 0px 15px 15px -15px;
}

.ser_card:hover .home_review_btn {
    border-color: var(--bs-animation-ball-bg);
}

.ser_card img {
    transition: transform 0.7s;
    transform-style: preserve-3d;
}

.ser_card:hover img {
    transform: rotateY(180deg);
}

/*  */

@media (max-width: 1200px) {
    .services_bg {
        background-image: none;
    }

    .ser_text h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .ser_text h5 {
        font-size: 18px;
    }
}