.Aboutus_bg {
    background-image: url("../../Image/About/left_arrow.svg"), url("../../Image/About/right_arrow.svg");
    background-repeat: no-repeat;
    background-position: left top, right bottom;
}

.Aboutus h1 {
    font-family: 'Nunito';
    font-size: 50px;
    line-height: 69px;
}

.Aboutus h1 span {
    font-family: 'Nunito';
    font-size: 50px;
    line-height: 69px;
    color: var(--bs-color-blue);
}

.Aboutus h5 {
    line-height: 30px;
}

.about_box1,
.about_box2,
.about_box3,
.about_box4,
.about_box5 {
    margin: 0 20px;
    border-radius: 15px;
    transition: 0.3s ease-in-out;
}

.about_box1 h2,
.about_box2 h2,
.about_box3 h2,
.about_box4 h2,
.about_box5 h2 {
    font-weight: 600;
}

.about_box1 {
    color: #008FAF;
    border: 1px solid #008FAF;
}

.about_box1:hover,
.about_box2:hover,
.about_box3:hover,
.about_box4:hover,
.about_box5:hover {
    box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
    transform: scale(1.01);
}

.about_box2 {
    color: #00D8A2;
    border: 1px solid #00D8A2;
}

.about_box3 {
    color: #edb536;
    border: 1px solid #edb536;
}

.about_box4 {
    color: #412A8E;
    border: 1px solid #412A8E;
}

.about_box5 {
    color: #FF7743;
    border: 1px solid #FF7743;
}

@media (max-width: 1400px) {

    .about_box1,
    .about_box2,
    .about_box3,
    .about_box4,
    .about_box5 {
        margin: 0 0;
    }
}

@media (max-width: 1200px) {

    .Aboutus h1,
    .Aboutus h1 span {
        font-size: 40px;
        line-height: 50px;
    }

    .Aboutus h5 {
        font-size: 16px;
    }

    .about_box1,
    .about_box2,
    .about_box3,
    .about_box4,
    .about_box5 {
        border: none;
    }

    .about_box1 h5,
    .about_box2 h5,
    .about_box3 h5,
    .about_box4 h5,
    .about_box5 h5 {
        font-size: 18px;
    }
}

@media (max-width: 576px) {

    .Aboutus h1,
    .Aboutus h1 span {
        font-size: 30px;
        line-height: 40px;
    }

    .Aboutus h5 {
        font-size: 14px;
    }
}