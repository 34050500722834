@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&display=swap');

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

#root {
  --home-main-bg: #F4F8FD;
  --bs-animation-ball-bg: #2c49a7;
  --font-light-color: #696969;
  --bs-color-blue: #086AD7;
  --bs-font-darkblue: #211E3B;
}

.text_light {
  color: var(--font-light-color);
}

.text_darkblue {
  color: var(--bs-font-darkblue);
}

input {
  width: 100%;
  height: 40px;
  background: #086AD70A;
  border: none;
  padding-left: 15px;
  border-radius: 7px;
  outline: none;
}

textarea {
  width: 100%;
  height: 100px;
  background: #086AD70A;
  border: none;
  padding-left: 15px;
  padding-top: 7px;
  border-radius: 7px;
  outline: none;
}

img {
  user-select: none;
}