.portfo_box {
    background-color: var(--home-main-bg);
    transition: 0.3s ease-in-out;
}

.portfo_box img {
    width: 100%;
}

.portfo_box a {
    text-decoration: none;
    color: var(--bs-font-darkblue);
}

.portfo_box h4 {
    font-family: 'Nunito', sans-serif;
}

.portfo_box:hover {
    box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.25);
    transform: scale(1.05);
}

.port_Btn {
    width: 100%;
    height: 50px;
    color: white;
    background-color: var(--bs-color-blue);
    border: 1px solid transparent;
    border-radius: 30px;
    font-size: 16px;
}

.port_Btn:hover, .port_Btn_1:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.port_Btn_1 {
    width: 100%;
    height: 50px;
    color: black;
    background-color: #fff;
    border: 1px solid var(--bs-color-blue);
    border-radius: 30px;
    font-size: 16px;
}